import React from "react";
import "./App.css";
import {
  FaReact,
  FaAws,
  FaNodeJs,
  FaWordpress,
  FaJsSquare,
  FaEnvelope,
  FaLinkedin,
  FaGithub,
  FaFileAlt,
} from "react-icons/fa";
import { SiFirebase, SiCypress, SiJest, SiAdobe } from "react-icons/si";

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="navbar">
          <ul className="nav-links">
            <li>
              <a href="#skills">Skills</a>
            </li>
            <li>
              <a href="#experience">Experience</a>
            </li>
            <li>
              <a href="#education">Education</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className="header-content">
          <div className="header-image">
            <img
              src="https://soylucianofiiles.blob.core.windows.net/soylucianofiles/lcn11.jpg?sp=r&st=2024-07-23T17:59:57Z&se=2026-07-24T01:59:57Z&spr=https&sv=2022-11-02&sr=b&sig=o4PkRA8cx5IEmSr4%2BKeuZ3YFeV0GgF8BWwCBiipZkvE%3D"
              alt="Luciano Crossa Niell"
              className="profile-image"
            />
          </div>
          <div className="header-info">
            <h1>Hi, I'm Luciano</h1>
            <p className="title">Senior Front-End Software Engineer</p>
            <p className="summary">
              With over five years of experience as a Front-End Developer, I
              specialize in crafting digital solutions centered around user
              experience. My expertise spans UI development, API integration,
              and cloud services, underpinned by a solid design foundation. I
              also have a passion for Arduino projects, blending creativity with
              technology to build innovative solutions. Currently based in
              Hillsboro, Oregon, I leverage my skills and local perspective to
              deliver impactful, user-centric designs.
            </p>
            <a
              href="https://docs.google.com/document/d/1-4nvDbCTHQMkgRI6Ay0Dm5OQtqNeTiKE/edit"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              View CV
            </a>
          </div>
        </div>
      </header>
      <main className="main">
        <section id="skills" className="section">
          <h2 className="section-title">Skills</h2>
          <div className="skills-grid">
            <div className="skill-card">
              <FaReact className="icon" />
              <h3>React.js</h3>
              <p>Expert in building interactive UIs with React.</p>
            </div>
            <div className="skill-card">
              <FaAws className="icon" />
              <h3>AWS</h3>
              <p>Experienced with AWS Amplify, EC2, and cloud services.</p>
            </div>
            <div className="skill-card">
              <FaNodeJs className="icon" />
              <h3>Node.js</h3>
              <p>Proficient in server-side development with Node.js.</p>
            </div>
            <div className="skill-card">
              <FaWordpress className="icon" />
              <h3>WordPress</h3>
              <p>Skilled in developing and customizing WordPress sites.</p>
            </div>
            <div className="skill-card">
              <FaJsSquare className="icon" />
              <h3>JavaScript</h3>
              <p>Strong command of JavaScript for dynamic web applications.</p>
            </div>
            <div className="skill-card">
              <SiFirebase className="icon" />
              <h3>Firebase</h3>
              <p>Experience with Firebase for real-time database solutions.</p>
            </div>
            <div className="skill-card">
              <SiCypress className="icon" />
              <h3>Cypress</h3>
              <p>Proficient in end-to-end testing with Cypress.</p>
            </div>
            <div className="skill-card">
              <SiJest className="icon" />
              <h3>Jest</h3>
              <p>Experienced in unit testing with Jest.</p>
            </div>
            <div className="skill-card">
              <SiAdobe className="icon" />
              <h3>Adobe XD</h3>
              <p>
                Skilled in creating wireframes and prototypes with Adobe XD.
              </p>
            </div>
          </div>
        </section>
        <section id="experience" className="section">
          <h2 className="section-title">Professional Experience</h2>
          <div className="experience-list">
            <div className="experience-item">
              <h3>Intel Corporation</h3>
              <p>
                <strong>UI Developer</strong>, April 2022 - May 2024
              </p>
              <ul>
                <li>
                  Developed UI patterns for a cloud-based micro-frontend PaaS
                  application.
                </li>
                <li>Collaborated on data modeling for API integration.</li>
                <li>Implemented automated testing and CI/CD processes.</li>
              </ul>
            </div>
            <div className="experience-item">
              <h3>Digital Marketing Group</h3>
              <p>
                <strong>SEO Specialist and Frontend Developer</strong>, March
                2016 – April 2022
              </p>
              <ul>
                <li>
                  Enhanced organic traffic by up to 34% through SEO practices.
                </li>
                <li>
                  Designed and developed mobile-first websites and landing
                  pages.
                </li>
                <li>
                  Automated internal processes with custom web applications.
                </li>
              </ul>
            </div>
            <div className="experience-item">
              <h3>BMCC Tribeca PAC</h3>
              <p>
                <strong>Marketing Associate</strong>, June 2013 – March 2015
              </p>
              <ul>
                <li>
                  Designed marketing collateral and created banner ad campaigns.
                </li>
                <li>Executed A/B testing to increase leads.</li>
                <li>
                  Worked closely on content strategy and audience
                  representation.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="education" className="section">
          <h2 className="section-title">Education</h2>
          <div className="education-list">
            <div className="education-item">
              <h3>Kennesaw State University</h3>
              <p>
                <strong>Master of Software Engineering</strong>, 2021 – 2024
              </p>
            </div>
            <div className="education-item">
              <h3>Savannah College of Art and Design</h3>
              <p>
                <strong>BFA in Graphic Design and Advertising</strong>, 2006
              </p>
            </div>
          </div>
        </section>
        <section id="contact" className="section">
          <h2 className="section-title">Contact</h2>
          <div className="contact-links">
            <p>
              <FaEnvelope className="contact-icon" />
              <a href="mailto:lcniell@gmail.com">lcniell@gmail.com</a>
            </p>
            <p>
              <FaLinkedin className="contact-icon" />
              <a href="https://www.linkedin.com/in/luciano-crossa-niell-47a71418/">
                LinkedIn
              </a>
            </p>
            <p>
              <FaGithub className="contact-icon" />
              <a href="https://github.com/lcniell123">GitHub</a>
            </p>
            <p>
              <FaFileAlt className="contact-icon" /> {/* Add CV icon */}
              <a href="https://docs.google.com/document/d/1-4nvDbCTHQMkgRI6Ay0Dm5OQtqNeTiKE/edit">
                View CV
              </a>
            </p>
          </div>
        </section>
      </main>
      <footer className="footer">
        <p>&copy; 2024 Luciano Crossa Niell</p>
      </footer>
    </div>
  );
}

export default App;
